<div
  *ngIf="
    filter.isCheckboxFilter || filter.isSelectionFilter;
    else popoverFilter
  "
  [ngClass]="wrapperClasses"
>
  <!-- checkbox -->
  <button
    *ngIf="filter.isCheckboxFilter"
    [class]="checkboxButtonClass"
    [class.is_disabled]="disableCheckbox"
    [attr.aria-disabled]="disableCheckbox"
    [attr.aria-label]="checkboxTooltip"
    [ngbTooltip]="checkboxTooltip"
    [attr.aria-checked]="currentOptions.has(filter.checkboxFilterId)"
    [attr.aria-pressed]="currentOptions.has(filter.checkboxFilterId)"
    [disabled]="disableCheckbox"
    (click)="toggleOption($event, filter.checkboxFilterId)"
    type="button"
    role="checkbox"
    class="btn guts-p-h-1 m-full-width"
    data-dgat="filter-405"
  >
    <input
      readonly="readonly"
      [checked]="currentOptions.has(filter.checkboxFilterId)"
      [class.is_disabled]="disableCheckbox"
      [class.hidden]="hideCheckboxIcon"
      [disabled]="disableCheckbox"
      [id]="filter.id"
      class="filter-checkbox"
      type="checkbox"
      data-dgat="filter-ba6"
    />
    <label
      for="{{ filter.id }}"
      [attr.aria-label]="checkboxName"
      [class.guts-p-l-half]="!hideCheckboxIcon"
      >{{ checkboxName }}</label
    >
  </button>

  <div *ngIf="filter.isSelectionFilter">
    <!-- selection filter without search -->
    <dgx-select
      *ngIf="!filter.canSearch"
      class="block l_flex l_flex-middle m-full-width full-width m-l_flex-justify-center"
      (selection)="selectDropdownOption($event)"
      (click)="trackEvent($event)"
      [ariaLabel]="filter.filters[0].subitems[0].title"
      templateOption="filter"
      [options]="filter.filters[0].subitems"
      [id]="filter.id"
      [isDisabled]="isDisabled"
      [selectedItem]="selectedOption"
      [placeholder]="filter.placeholderTitle"
      labelKey="title"
      trackBy="model.id"
      dgatInput="filter-eda"
    >
    </dgx-select>

    <!-- selection filter with search -->
    <div *ngIf="filter.canSearch">
      <div [ngClass]="wrapperClasses" class="filter-component rel">
        <button
          data-dgat="filter-x3d"
          (click)="trackEvent($event)"
          [class]="popoverButtonClass"
          [class.is_disabled]="isDisabled || filter.isDisabled"
          [disabled]="isDisabled || filter.isDisabled"
          [dgxPopoverTrigger]="filterPopover"
          class="filter-btn btn guts-p-h-1 guts-p-v-half l_flex l_flex-middle m-full-width m-l_flex-justify-center"
          placement="bottom"
          type="button"
          [id]="filter.id"
        >
          <span class="block">
            {{
              selectedOption && selectedOption.title
                ? selectedOption.title
                : filter.title
            }}
          </span>
          <df-icon
            icon="chevron-down"
            size="small"
            class="block guts-m-l-3-quart"
          ></df-icon>
        </button>
        <dgx-popover
          #filterPopover
          (isOpenChange)="popoverToggled($event)"
          [popoverArrowHidden]="true"
          placement="bottom-left"
        >
          <ng-container *ngTemplateOutlet="commonPopover"></ng-container>
        </dgx-popover>
      </div>
    </div>
  </div>
</div>

<ng-template #commonPopover>
  <!--
        Swallow click events on the UL and children so that dgxPopover stays open.
        TODO: This should frankly be an option on dgx-popover, to control what actions
        close the popover.
       -->
  <section class="guts-p-v-1-half" (click)="$event.stopPropagation()">
    <div class="overflow-scroll-gradient">
      <div
        class="overflow-scroll-gradient__scroller scrollbar guts-p-h-1-half guts-p-b-half rel"
      >
        <!-- optional search field -->
        <!-- TODO: Make this work for multi-filter filters -->
        <dgx-search-field-wrapper
          *ngIf="filter.canSearch && !filter.isFilterCollection"
          styles="guts-m-t-1 guts-m-b-1-half"
        >
          <label class="a11y-hide" for="filter-{{ filter.id }}-search">{{
            i18n.Core_Search
          }}</label>
          <input
            [formControl]="searchField"
            [placeholder]="i18n.Core_Search"
            id="filter-{{ filter.id }}-search"
            autofocus
            minlength="2"
            type="search"
            (click)="trackEvent($event)"
            (change)="trackEvent($event)"
            data-dgat="filter-0dd"
            autocomplete="off"
          />
        </dgx-search-field-wrapper>
        <!-- filters -->
        <!-- No results paragraph -->
        <div *ngIf="searchField.value.length > 2 && noResults">
          <p class="par--light par--small center-text guts-m-b-1">
            {{
              'Core_NoResultsFor'
                | translate
                  : {
                      searchTerm: searchField.value,
                    }
            }}
          </p>
        </div>
        <div class="no-wrap l_flex">
          <div class="filter-items">
            <!-- Search results list -->
            <div
              *ngFor="
                let currFilter of filter.canSearch
                  ? displayFilters
                  : filter.filters
              "
              data-dgat="filter-239"
            >
              <div
                *ngIf="filter.isFilterCollection"
                class="par--small font-bold guts-p-t-half guts-p-b-1 guts-p-h-half"
              >
                {{ currFilter.title }}
              </div>
              <div
                [attr.aria-multiselectable]="true"
                [closeOnSelect]="false"
                [closeOnTab]="false"
                role="listbox"
                id="allOptions"
              >
                <button
                  *ngFor="let option of currFilter.subitems; trackBy: trackByFn"
                  [attr.aria-selected]="currentOptions.has(option.model.id)"
                  [class.is_disabled]="isOptionDisabled(option.model)"
                  [disabled]="isOptionDisabled(option.model)"
                  (click)="
                    !filter.isSelectionFilter
                      ? toggleOption($event, option.model.id)
                      : selectDropdownOption(option)
                  "
                  role="option"
                  [disableTabIndex]="false"
                  class="listbox--option l_flex guts-p-h-half"
                  data-dgat="filter-select-ed9"
                >
                  <input
                    *ngIf="!filter.isSelectionFilter"
                    [checked]="currentOptions.has(option.model.id)"
                    [class.is_disabled]="isOptionDisabled(option.model)"
                    [disabled]="isOptionDisabled(option.model)"
                    class="guts-m-r-1"
                    data-dgat="filter-b3b"
                    readonly="readonly"
                    type="checkbox"
                    tabindex="-1"
                    [attr.aria-label]="option.title | displayType"
                  />
                  <div *ngIf="option.model.icon" class="guts-m-r-half">
                    <df-icon
                      [class]="getIconClass(option.model)"
                      [icon]="option.model.icon.name"
                      [size]="getIconSize(option.model)"
                      class="block"
                    ></df-icon>
                  </div>
                  <div [class.color-ebony-a18]="isOptionDisabled(option.model)">
                    {{
                      ellipsis > 0
                        ? (option.title | displayType | ellipsis: ellipsis)
                        : (option.title | displayType)
                    }}
                  </div>
                  <div
                    *ngIf="option.model.count"
                    class="filter-item-count l_flex-grow right-text"
                  >
                    {{
                      option.model.count < countLimit
                        ? option.model.count
                        : countLimit + '+'
                    }}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!updateOnDelay || numSelected > 0) && !filter.isSelectionFilter"
      class="filter-actions guts-p-t-2 guts-p-h-2 border-top"
    >
      <button
        *ngIf="!updateOnDelay"
        (click)="trackEvent($event); applyFilter(true)"
        class="save-btn full-width"
        df-button
        dfButtonType="primary"
        data-dgat="filter-e45"
      >
        {{ i18n.Core_ApplyChanges }}
      </button>
      <div
        *ngIf="numSelected > 0"
        [class.center-text]="!filter?.isFilterCollection"
        [class.right-text]="filter?.isFilterCollection"
        [ngClass]="{
          'clear-btn guts-p-t-1': !updateOnDelay,
        }"
      >
        <button
          (click)="resetFilter($event)"
          class="color-blue par--small font-medium uhover"
          data-dgat="filter-6af"
        >
          {{ clearFilterText }}
        </button>
      </div>
    </div>
  </section>
</ng-template>

<!-- list in popover -->
<ng-template #popoverFilter>
  <div [ngClass]="wrapperClasses" class="filter-component rel">
    <button
      data-dgat="filter-03d"
      (click)="trackEvent($event)"
      [class]="popoverButtonClass"
      [class.is_disabled]="isDisabled || filter.isDisabled"
      [disabled]="isDisabled || filter.isDisabled"
      [dgxPopoverTrigger]="filterPopover"
      class="filter-btn btn guts-p-h-1 guts-p-v-half l_flex l_flex-middle m-full-width m-l_flex-justify-center"
      placement="bottom"
      type="button"
      [id]="filter.id"
    >
      <span class="block">
        {{
          numSelected > 0 && filter.altTitle ? filter.altTitle : filter.title
        }}
        <span
          class="num-selected-filter-items guts-p-l-half"
          *ngIf="numSelected > 0 && !filter.isDisabled"
          >{{ numSelected }}</span
        >
      </span>
      <df-icon
        icon="chevron-down"
        size="small"
        class="block guts-m-l-3-quart"
      ></df-icon>
    </button>
    <dgx-popover
      #filterPopover
      (isOpenChange)="popoverToggled($event)"
      [popoverArrowHidden]="true"
      placement="bottom-left"
    >
      <ng-container *ngTemplateOutlet="commonPopover"></ng-container>
    </dgx-popover>
  </div>
</ng-template>
