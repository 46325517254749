import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { ngbTypeaheadInputFormatter } from '@app/shared/utils/ngb-helpers';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { roughlyMatching } from '@app/utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ArticlePathwaysPlansInputFacade } from '../inputs/article/services/pathways-and-plans/article-pathways-plans.facade';
import { VideoPathwaysPlansInputFacade } from '../inputs/video/services/pathways-and-plans/video-pathways-plans.facade';
import { contentOwnerIdValidator } from './validators';
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils';
import { CoursePathwaysPlansFacade } from '../inputs/course/services/pathways-and-plans/course-pathways-plans.facade.service';

export const calculateDurationBasedOnSessionDetails = (
  startDate: NgbDateStruct,
  endDate: NgbDateStruct,
  startTime: string,
  endTime: string
): { durationHours: number; durationMinutes: number } => {
  const startDateTime: Date = new Date(
    startDate.year,
    startDate.month - 1,
    startDate.day,
    parseInt(startTime.split(':')[0]),
    parseInt(startTime.split(':')[1])
  );
  const endDateTime: Date = new Date(
    endDate.year,
    endDate.month - 1,
    endDate.day,
    parseInt(endTime.split(':')[0]),
    parseInt(endTime.split(':')[1])
  );
  const millisecondsInAMinute = 60000;
  const totalDurationInMinutesOnly: number = Math.ceil(
    (endDateTime.getTime() - startDateTime.getTime()) / millisecondsInAMinute
  );
  const durationHours: number = Math.floor(totalDurationInMinutesOnly / 60);
  const durationMinutes: number = totalDurationInMinutesOnly % 60;

  return { durationHours, durationMinutes };
};

/**
 * If the user submits and there are invalid fields, move the focus to the first invalid field
 * @deprecated - use handleFocusOnSubmit.
 */
export const focusOnFirstInvalidField = (
  formControlObjectEntries: [string, AbstractControl][],
  durationFormEntry?: [string, AbstractControl],
  sessionDetailsFormEntry?: [string, AbstractControl]
): void => {
  const firstInvalidFieldName = formControlObjectEntries.reduce(
    (acc, [key, value]) => {
      if (value.status === 'INVALID') {
        acc.push(key);
      }
      return acc;
    },
    []
  )[0];

  // Some fields get special treatment for focus because the inputs are deeply nested with different ids than the form field key
  let inputIdForFocus = firstInvalidFieldName;
  if (firstInvalidFieldName === 'contentUploader') {
    inputIdForFocus = 'scormUploader';
    const parent = document.getElementById('scormUploader');
    // focus retry button if possible.
    const retryButton = parent.getElementsByClassName(
      '__retry-button'
    )[0] as HTMLElement;
    // otherwise, focus hidden input to focus the whole upload field
    const hiddenInput = parent.getElementsByClassName(
      'hide-focus'
    )[0] as HTMLElement;

    if (retryButton) {
      retryButton.focus();
      return;
    }
    if (hiddenInput) {
      hiddenInput.focus();
      return;
    }
  } else if (firstInvalidFieldName === 'owner') {
    inputIdForFocus = 'user-search';
  } else if (firstInvalidFieldName === 'advancedSettings') {
    inputIdForFocus = 'group-search-input';
  } else if (firstInvalidFieldName === 'durationForm') {
    if (
      (durationFormEntry[1] as FormGroup)?.controls.durationHours.status ===
      'INVALID'
    ) {
      inputIdForFocus = 'durationHours';
    } else {
      inputIdForFocus = 'durationMinutes';
    }
  } else if (firstInvalidFieldName === 'skills') {
    inputIdForFocus = 'tag-search-input';
  } else if (firstInvalidFieldName === 'sessionDetails') {
    const controlsObjsInDomOrder = getSessionDetailsControlsInDomOrder(
      (sessionDetailsFormEntry[1] as FormGroup)?.controls
    );
    const firstInvalidControl = controlsObjsInDomOrder.filter(
      (controlObj) => controlObj.control.status === 'INVALID'
    )[0];

    if (firstInvalidControl.name === 'locationType') {
      inputIdForFocus = 'isInPerson';
    } else {
      inputIdForFocus = firstInvalidControl.name;
    }
  }
  document.getElementById(inputIdForFocus)?.focus();
};

/**
 * Grab the first focusable invalid child on submit.
 *
 * @param form - Native form element.
 */
export const handleFocusOnSubmit = (
  form: HTMLElement,
  extraFormGroups = ['sessionDetails'],
  extraElements = ['dgx-address-suggest']
): void => {
  const excludedElements = ['form', 'fieldset', 'div', ...extraElements];
  const excludedParentElements = ['form'];
  const excludedFormGroupNames = ['advancedSettings', ...extraFormGroups];
  const potentialInputs = ['button', 'input', 'textarea'];

  // build query
  let query = '.ng-invalid';
  // elements we don't want to ever focus
  excludedElements.forEach((elem) => {
    query += `:not(${elem})`;
  });
  // situations where we want to focus the *children* of .ng-invalid
  potentialInputs.forEach((input) => {
    query += ',.ng-invalid';
    // elements we don't want to focus the children of
    excludedParentElements.forEach((parent) => {
      query += `:not(${parent})`;
    });
    // formgroups we don't want to focus the children of
    excludedFormGroupNames.forEach((fg) => {
      query += `:not([formgroupname=${fg}])`;
    });
    // finally, grab the first matching child of this type
    query += ` ${input}`;
  });
  // grab our selected elements
  const selected: HTMLElement = form.querySelector(query);
  // focus, if truthy.
  selected?.focus();
};

/**
 * Wrapper for ngbTypeaheadInputFormatter to provide our default
 * type and prop.
 *
 * @param item - Search result item.
 */
export const inputFormatter = (item: AutocompleteItem) =>
  ngbTypeaheadInputFormatter<AutocompleteItem>(item, 'label');

export const onChangeOfAddToCatalogValue = async (
  isAddingToCatalog: boolean,
  planPathForm: FormGroup,
  facade: VideoPathwaysPlansInputFacade | ArticlePathwaysPlansInputFacade,
  defaultOrgId?: number
) => {
  const contentOwnerControl = planPathForm.get('owner');
  if (isAddingToCatalog) {
    contentOwnerControl.setValidators([Validators.required]);

    await facade.fetchUrlDuplicates(facade.snapshot.entryUrl, defaultOrgId);
  } else {
    contentOwnerControl.clearValidators();
  }

  contentOwnerControl.updateValueAndValidity();
};

/**
 * Like onChangeOfAddToCatalogValue, but also makes URL field required
 * (if adding by url or adding to the catalog)
 *
 * @param isCatalogContent
 * @param planPathForm
 * @param facade
 * @param isAddingByUrl
 */
export const onChangeOfAddCourseToCatalogValue = async (
  isCatalogContent: boolean,
  planPathForm: FormGroup,
  facade: CoursePathwaysPlansFacade,
  isAddingByUrl: boolean
) => {
  const ownerField = planPathForm.get('owner');
  const courseUrlField = planPathForm.get('courseUrl');
  const urlValidation =
    isCatalogContent || isAddingByUrl
      ? [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)]
      : [Validators.pattern(HTTP_REQUIRED_URL_PATTERN)];

  if (isCatalogContent) {
    ownerField.setValidators([Validators.required, contentOwnerIdValidator]);
    courseUrlField.setValidators(urlValidation);
    await facade.fetchUrlDuplicates(facade.snapshot.courseUrl, facade.orgId);
  } else {
    ownerField.setValidators([contentOwnerIdValidator]);
    courseUrlField.setValidators(urlValidation);
  }
  ownerField.updateValueAndValidity();
  courseUrlField.updateValueAndValidity();
};

/**
 * Make the input field empty if it only contains whitespace.
 *
 * @param form
 * @param formControlName
 */
export const resetIfWhitespaceOnly = (
  form: FormGroup,
  formControlName: string
): void => {
  const control = form.get(formControlName);
  const isWhitespace = (control.value || '').trim().length === 0;
  if (isWhitespace) {
    control.setValue('');
  }
};

/**
 * When editing a content item from a pathway or plan,
 * hide the url field IF the content is from the catalog AND is hosted content
 * @param isContentFromCatalog
 * @param isEditing
 * @param hostedContentDetails
 * @returns boolean
 */
export const pathPlanHideUrlField = (
  isContentFromCatalog: boolean,
  isEditing: boolean,
  hostedContentDetails?: HostedContentMetadata
): boolean => isEditing && isContentFromCatalog && !!hostedContentDetails;

/**
 * @deprecated - use handleFocusOnSubmit
 * @param controls
 */
const getSessionDetailsControlsInDomOrder = (controls) => {
  const controlNamesInDomOrder = [
    'isRegistrationAvailable',
    'isRegistrationUrlInputUrl',
    'registrationUrl',
    'locationType',
    'locationAddress',
    'locationUrl',
    'startDate',
    'startTime',
    'endDate',
    'endTime',
    'timeZoneId',
  ];

  const reorderedControls = controlNamesInDomOrder.map((controlName) => ({
    name: controlName,
    control: controls[controlName],
  }));
  return reorderedControls;
};
/**
 * Returns true if the old and new value are different, and not both nullish.
 * (e.g., value in form is '', value on model is undefined.)
 *
 * @param oldValue
 * @param newValue
 */
export function shouldUpdateModel(oldValue: any, newValue: any): boolean {
  return !roughlyMatching(oldValue, newValue, '');
}
