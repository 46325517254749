/* eslint-disable max-classes-per-file */

import { Entity, StoreState } from '@app/shared/rsm';

import {
  PathwayPermissionsModel,
  PathwaySection,
  PathwayStep,
  PathwayDetailsModel,
  PathwaySubsection,
  StepModalProperties,
  PathwayBinItem,
  PathwayModalSettingsChecks as PathwayModalSettingsChecks,
} from '@app/pathways/rsm/pathway-api.model';
import { ActionsList, PathwayTracking } from './utils';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { EmitEvent, TrackingEventData } from '@dg/shared-services';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { UserSearchItem } from '@app/user/user-api.model';
import { ReorderItem } from '@app/reorder-modal/reorder-modal.model';

export interface GetPathwayWithPermissionsParams {
  pathId?: number;
  obfuscatedId?: string;
  code?: string;
  useResourceImages?: boolean;
}

/**
 * Possible query params for a pathway
 */
export interface PathwayUrlQueryParams {
  /** Org sso share link */
  orgsso?: string;
  /** Tracking link param */
  tltag?: string;
  /**  */
  pathId?: string;
  code?: string;
}

export interface LogPathwayViewParams {
  pathId: number;
  tlTag?: string;
}

export interface PathwayEntity extends Entity {
  target: PathwayDetailsModel;
}

// ******************************************
// State
// ******************************************

/**
 * Which parts of the pathway are active/selected?
 */
export interface PathwayActivation {
  type?: ActionType;
  section: number;
  subsection?: number;
}

export interface PathwayState extends StoreState {
  permissions: PathwayPermissionsModel;
  pathway?: PathwayDetailsModel; // only 1 pathway in this store
  isEditMode: boolean;
  version: string;
  tltag: string;
  activations: PathwayActivation;
  itemBin: PathwayBinItem[];

  // Faux section/subsections
  /** True or false value for whether the pathway has a faux section. (Edit mode only.)*/
  hasFauxSection?: boolean;
  /** Array of faux subsection nodes. (Edit mode only.) */
  fauxSubsections?: string[];
}

export interface PathwayComputedState {
  isMobileView: boolean;
  isEditMode: boolean;
  canAuthor: boolean;
  inviteUrl: string;
  visibilityLabel: string;
  isConsumerUser: boolean;

  // Step summaries
  totalSections: number;
  totalSteps: number;
  totalStepsDisplay: string;
  optionalSteps: number;
  totalCompletedSteps: number;
  optionalStepsDisplay: string;

  // Progress information
  firstSectionInProgress: string;
  isComplete: boolean;
  durationHours: string;
  durationMinutes: string;
  hideHeaderImage: boolean;
  sectionTotalDisplay: string;
  endorsedImageUrl: string;
  isChannel: boolean;
  canRecommend: boolean;

  // settings information
  disableLearnerSkillsRegistry: boolean;
  showPathwayBadgeTrigger: boolean;
  showPathwaySurvey: boolean;
}
export type PathwayNode = PathwaySection | PathwaySubsection | PathwayStep;

// ******************************************
// View Model Interfaces
// ******************************************

export interface PathwayAPI {
  updateVersion: (version: string) => Promise<boolean>;
  activateSection: (section: number) => void;
  activateSubsection: (section: number, subsection: number) => void;
}

export type PathwayViewModel = PathwayAPI & PathwayState & PathwayComputedState;

// ******************************************
// Initialization Utils
// ******************************************

// TODO: Can we add comments to those? what are the mapped to?
const initPermissionsState = (): PathwayPermissionsModel => ({
  canAuthorPathway: false,
  canEditPathway: false,
  canManageGroups: false,
  canManagePathway: false,
  canRecommendItems: false,
  canViewAuthors: false, // controlled by ops tool setting "Hide List of Pathway/Plan/Directories Collaborators"
  canViewEnrolleesTab: false,
  canViewInsightsTab: false,
  canViewMembers: false,
  canViewPathwayEnrollees: false,
  canViewPathwayReports: false,
  isAuthor: false,
  isTrackableLinksDisabled: false,
});

export const initPathwayState = (): PathwayState => ({
  permissions: initPermissionsState(),
  isEditMode: false,
  version: '',
  tltag: '',
  activations: {
    // currently only 1 set is allowed/tracked
    section: 1,
    subsection: 1,
  },
  itemBin: [],
  hasFauxSection: false,
  fauxSubsections: [],
});

// ******************************************
// Pathway Activation Actions
// ******************************************
export type ActionType =
  | PathwayLevel.PATHWAY
  | PathwayLevel.SECTION
  | PathwayLevel.SUBSECTION
  | PathwayLevel.STEP;

/**
 * For use with the event bus.
 * NOTE: It's *critical* that the second property, the one we want to pass
 * to the event bus, is named 'data'.
 */
export class PathwayEvent implements EmitEvent<Partial<PathwayActivation>> {
  constructor(
    public type: ActionsList,
    public data: Partial<PathwayActivation>
  ) {}
}

// ******************************************
// Pathway Authoring Actions
// ******************************************

export type AuthoringType =
  | AuthoringSectionType
  | AuthoringSubsectionType
  | AuthoringStepType
  | AuthoringCommonType
  | PathwayActionTypes;

export type PathwayActionTypes =
  | 'toggleEnrollment'
  | 'settings'
  | 'share'
  | 'editAuthors'
  | 'confirmDelete'
  | 'toggleHasFaux'
  | 'updatePathwayVisibility';

export type AuthoringSectionType = 'createAndPopulate' | 'toggleHasFaux';
export type AuthoringSubsectionType =
  | 'createAndPopulate'
  | 'addContent'
  | 'updateDescription';
export type AuthoringStepType =
  | 'addContentToBin'
  | 'removeContentFromBin'
  | 'updateItemInBin'
  | 'editInternalDetails'
  | 'toggleOptional'
  | 'toggleShouldDisplayNote'
  | 'deleteAuthorNote'
  | 'editDetails'
  | 'editCustomDetails'
  | 'removeStep'
  | 'moveStep';

export type AuthoringCommonType =
  | 'create'
  | 'reorder'
  | 'delete'
  | 'move'
  | 'updateField';

export type NodeMap = Record<string, unknown>; // describes the ordering of nodes in pathway
export interface PathwayMoveNodeInput {
  pathwayId: number;
  node: string;
  type: PathwayLevel.SUBSECTION | PathwayLevel.STEP;
  moveAfterNode: string;
  beforeNode: string; // Null when moving steps or subsections
  moveToBin?: boolean;
}

export interface AddContentNodes {
  beforeNode: string;
  afterNode: string;
}

export type AddContentModalType = 'bin' | 'manual' | 'search';
export interface AuthoringFieldParams {
  field: string;
  value: string;
}
export interface AuthoringParams extends AuthoringFieldParams {
  id: number;
  pathId: number;

  node: string;
  parentNode: string;
  title: string;
  authors: UserSearchItem[];
  note: string;
  shouldDisplayNote: boolean;
  hasFaux: boolean;

  request: NodeMap;
  version: number;

  sectionNode: PathwaySection;
  subsection: PathwaySubsection;
  step: PathwayStep;
  sectionNumber: number;
  subsectionNumber: number;
  stepProperties: StepModalProperties;

  nodes: AddContentNodes;
  afterNode: string;
  reorderedItems: ReorderItem[];
  contentItems: LearningResourceViewModel[];
  item: PathwayBinItem;
  checks: PathwayModalSettingsChecks;

  isEnrolled: boolean;
  isEndorsed: boolean;
  isSelected: boolean;
  isAllSelected: boolean;

  privacyLevel: Visibility;
  focusElement: HTMLElement;
}

// Message properties are non-localized keys and must be translated
export interface AuthorNotifications {
  message?: {
    done?: string;
    doneParams?: any;
  };
  track?: PathwayTracking;
  trackBatch?: TrackingEventData[]; // Added for trackBatch support which is added in Pathway Authoring
}

export enum PathwayActionSuccess {
  CREATED_SECTION = 'Pathways_CreatedSection',
  REORDERED_SECTION = 'Pathways_ReorderedSections',
  DELETED_SECTION = 'Pathways_DeletedSection',

  CREATED_SUBSECTION = 'Pathways_CreatedSubsection',
  REORDERED_SUBSECTION = 'Pathways_SubsectionsReordered',
  DELETED_SUBSECTION = 'Pathways_DeletedSubsection',
  MOVE_SUBSECTION = 'Pathways_MovedSubsection',
  ADDITEM_SUBSECTION = 'Pathways_AddContentItemSuccess',
  ADDITEMS_SUBSECTION = 'Pathways_AddContentItemsSuccess',

  REORDERED_STEP = 'Pathways_ItemsReordered',
  MOVE_STEP = 'Pathways_MovedItem',
  ADDTOBIN_STEP = 'Pathways_MovedToHoldForLater', // ALSO used for deleting steps.
  OPTIONAl_STEP = 'Pathways_RequiredContentSuccess',
  REQUIRED_STEP = 'Pathways_AdditionalContentSuccess',

  EDIT_DETAILS = 'Pathways_ItemSavedFormat',
}

export enum PathwayLevel {
  PATHWAY = 'pathway',
  SECTION = 'section',
  SUBSECTION = 'subsection',
  STEP = 'step',
}

export class AuthoringAction {
  constructor(
    public type: ActionType,
    public action: AuthoringType,
    public payload: Partial<AuthoringParams>,
    public notifications?: AuthorNotifications,
    public updateStoreOnly?: boolean //TODO: update for two-binding
  ) {
    updateStoreOnly ||= false;
  }
  public set doneMessage(message: PathwayActionSuccess) {
    this.notifications.message.done = message;
  }
  // set message translations with params
  public set doneParams(params: { title: string }) {
    this.notifications.message.doneParams = params;
  }
  // TODO: straighten out this type; other modals (search, etc) are using string, but we were using TrackingType
  public set trackingEvent(event: any) {
    this.notifications.track = event;
  }
  public set batchTrackingEvent(batchEvents: TrackingEventData[]) {
    this.notifications.trackBatch = batchEvents;
  }
}

const emptyNotification = () => ({ message: { done: '', error: '' } });
// *****************************************
// Pathway Actions
// *****************************************
export const toggleEnrollment = (
  isEnrolled: boolean,
  privacyLevel: Visibility,
  id: number
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.PATHWAY,
    'toggleEnrollment',
    { isEnrolled, privacyLevel, id },
    {
      // TODO: Check with Erin/design about possbile success message here. Current pathways does not use one.
      message: {},
      track: isEnrolled ? PathwayTracking.ENROLL : PathwayTracking.UNENROLL,
    }
  );

export const updateVisibility = (
  privacyLevel: Visibility,
  id: number,
  title: string
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.PATHWAY,
    'updatePathwayVisibility',
    { privacyLevel, id, title },
    {
      message: {},
      track: PathwayTracking.UPDATE_VISIBILITY,
    }
  );
export const editAuthors = (
  checks: PathwayModalSettingsChecks
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.PATHWAY,
    'editAuthors',
    { checks },
    { message: {} }
  );

export const settings = (checks: PathwayModalSettingsChecks): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.PATHWAY,
    'settings',
    { checks },
    {
      message: {},
    }
    // Success Message added from input-notification service
  );

export const share = (focusElement: HTMLElement): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.PATHWAY,
    'share',
    { focusElement },
    {
      message: {},
    }
  );

export const confirmDelete = (): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.PATHWAY,
    'confirmDelete',
    {},
    {
      message: {},
      track: PathwayTracking.DELETE_PATHWAY,
    }
  );
// ******************************************
// Section Actions
// ******************************************

export const toggleHasFaux = (
  type: ActionType,
  id: number,
  node: string,
  hasFaux = false
): AuthoringAction => {
  let done = '';

  switch (type) {
    case PathwayLevel.SECTION:
      done = hasFaux
        ? PathwayActionSuccess.CREATED_SECTION
        : PathwayActionSuccess.DELETED_SECTION;
      break;
    case PathwayLevel.SUBSECTION:
      done = hasFaux
        ? PathwayActionSuccess.CREATED_SUBSECTION
        : PathwayActionSuccess.DELETED_SUBSECTION;
      break;
  }

  return new AuthoringAction(
    type,
    'toggleHasFaux', // payload
    { id, node, hasFaux },
    // notifications
    {
      message: {
        done,
      },
    }
  );
};

// ******************************************
// Subsection Actions
// ******************************************

export const addContent = (nodes: AddContentNodes): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.SUBSECTION,
    'addContent',
    { nodes },
    {
      message: {},
    }
    // Success Message + tracking action added after data is returned from a closed add content modal
  );

// ******************************************
// Step Actions
// ******************************************
/**
 * Create an action 'command' that will hold content for later
 */
export const addContentToBin = (
  pathId: number,
  contentItems: LearningResourceViewModel[]
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.STEP,
    'addContentToBin',
    { pathId, contentItems },
    {
      message: {
        done: PathwayActionSuccess.ADDTOBIN_STEP,
      },
    } // Tracking handled in AddContentModal
  );

export const removeContentFromBin = (item: PathwayBinItem): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.STEP,
    'removeContentFromBin',
    { item },
    {
      message: {},
    }
  );

export const updateItemInBin = (
  isSelected: boolean,
  isAllSelected: boolean,
  item: PathwayBinItem
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.STEP,
    'updateItemInBin',
    { item, isSelected, isAllSelected },
    {
      message: {},
    }
  );

/**
 * Create an action 'command' that will edit the internal details of the step.
 *
 * Note: Editing the internal details does edit item in catalog
 */
export const editInternalDetailsStep = (
  stepProperties: StepModalProperties,
  step: PathwayStep
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.STEP,
    'editInternalDetails',
    { stepProperties, step },
    {
      message: {},
    }
    // Success Message added from input-notification service
  );

/**
 * Create an action 'command' that will toggle the a step to be Required or Optional
 */
export const toggleOptionalStep = (
  pathId: number,
  step: PathwayStep
): AuthoringAction => {
  const isOptional = step.reference.pathwayStepDetails?.isOptional;
  const done = isOptional
    ? PathwayActionSuccess.OPTIONAl_STEP
    : PathwayActionSuccess.REQUIRED_STEP;

  return new AuthoringAction(
    PathwayLevel.STEP,
    'toggleOptional',
    { pathId, step },
    {
      message: {
        done,
      },
    }
  );
};

/**
 * Create an action 'command' that will toggle the display of the author note of a step
 */
export const toggleShouldDisplayNote = (
  step: PathwayStep,
  shouldDisplayNote: boolean
): AuthoringAction => {
  const notification = emptyNotification();

  return new AuthoringAction(
    PathwayLevel.STEP,
    'toggleShouldDisplayNote',
    { step, shouldDisplayNote },
    notification
  );
};

/**
 * Create an action 'command' that will delete the pathway author note.
 */
export const deleteAuthorNote = (step: PathwayStep): AuthoringAction => {
  const notification = emptyNotification();

  return new AuthoringAction(
    PathwayLevel.STEP,
    'deleteAuthorNote',
    { step },
    notification
  );
};

/**
 * Create an action 'command' that will edit the details of the step.
 *
 * Note: Editing details does not edit item in catalog
 */
export const editDetailsStep = (
  stepProperties: StepModalProperties,
  step: PathwayStep
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.STEP,
    'editDetails',
    { stepProperties, step },
    {
      message: { done: PathwayActionSuccess.EDIT_DETAILS },
    }
  );

/**
 * Create an action 'command' that will edit the details of the
 * custom step for post or task.
 *
 */
export const editCustomDetailsStep = (
  stepProperties: StepModalProperties,
  step: PathwayStep
): AuthoringAction =>
  new AuthoringAction(
    PathwayLevel.STEP,
    'editCustomDetails',
    { stepProperties, step },
    {
      message: { done: PathwayActionSuccess.EDIT_DETAILS },
    }
  );

const isPathwaySection = (target: PathwayNode): target is PathwaySection =>
  target?.hasOwnProperty('totalLessons');
const isPathwaySubsection = (
  target: PathwayNode
): target is PathwaySubsection => target?.hasOwnProperty('totalSteps');
/****************************************************************
 * COMMON ACTIONS
 ****************************************************************/
/**
 * Create an action 'command' that will update a "field" of a pathway
 * section, subsection, or step.
 *
 * Note: supported fields are 'title', 'description', and 'note'
 */
export const updateNodeField = (
  target: PathwayNode,
  value: string,
  field: string,
  updateStoreOnly = false,
  version = undefined
): AuthoringAction => {
  const action = 'updateField';

  const isSection = isPathwaySection(target);
  const isSubsection = isPathwaySubsection(target);
  const type = isSection
    ? PathwayLevel.SECTION
    : isSubsection
      ? PathwayLevel.SUBSECTION
      : PathwayLevel.STEP;

  const notification = emptyNotification();
  const payload = {
    value,
    field,
    version,
    sectionNode: undefined,
    subsection: undefined,
    step: undefined,
  };
  switch (type) {
    case PathwayLevel.SECTION:
      payload.sectionNode = target;
      break;
    case PathwayLevel.SUBSECTION:
      payload.subsection = target;
      break;
    default:
      payload.step = target;
  }

  return new AuthoringAction(
    type,
    action,
    payload,
    notification,
    updateStoreOnly
  );
};

/**
 * Create an action 'command' that will "Create" a pathway
 * section, subsection.
 * Note: 'node' will be undefined if creating a section
 */
export const createNode = (
  type: ActionType,
  id: number,
  node: string
): AuthoringAction => {
  const action = 'create';

  let done: string;
  let track: PathwayTracking;
  switch (type) {
    case PathwayLevel.SECTION:
      done = PathwayActionSuccess.CREATED_SECTION;
      track = PathwayTracking.CREATE_SECTION;
      break;
    case PathwayLevel.SUBSECTION:
      done = PathwayActionSuccess.CREATED_SUBSECTION;
      track = PathwayTracking.CREATE_SUBSECTION;
      break;
  }
  const notification = { message: { done }, track };

  const payload = {
    id,
    node,
  };

  return new AuthoringAction(type, action, payload, notification);
};

/**
 * Create an action 'command' that will "Create" a pathway
 * section or subsection, then populate them with a given
 * amount of content.
 * Note: 'node' will be undefined if creating a section
 */
export const createNodeAndPopulate = ({
  id,
  type,
  nodes,
  parentNode,
}: {
  id: number;
  type: ActionType;
  nodes: AddContentNodes;
  parentNode: string;
}): AuthoringAction => {
  const action = 'createAndPopulate';

  let done: string;
  let track: PathwayTracking;
  switch (type) {
    case PathwayLevel.SECTION:
      done = PathwayActionSuccess.CREATED_SECTION;
      track = PathwayTracking.CREATE_SECTION;
      break;
    case PathwayLevel.SUBSECTION:
      done = PathwayActionSuccess.CREATED_SUBSECTION;
      track = PathwayTracking.CREATE_SUBSECTION;
      break;
  }
  const notification = { message: { done }, track };

  const payload = {
    id,
    nodes,
    parentNode,
  };

  return new AuthoringAction(type, action, payload, notification);
};

/**
 * Create an action 'command' that will "delete" a pathway
 * section, subsection, step.
 */
export const deleteNode = (
  type: ActionType,
  id: number,
  node: string
): AuthoringAction => {
  const action = 'delete';

  let done: string;
  let track: PathwayTracking;
  switch (type) {
    case PathwayLevel.SECTION:
      done = PathwayActionSuccess.DELETED_SECTION;
      track = PathwayTracking.DELETE_SECTION;
      break;
    case PathwayLevel.SUBSECTION:
      done = PathwayActionSuccess.DELETED_SUBSECTION;
      track = PathwayTracking.DELETE_SUBSECTION;
      break;
    case PathwayLevel.STEP:
      done = PathwayActionSuccess.ADDTOBIN_STEP; // Deleted steps are moved to Hold For Later.
      track = PathwayTracking.DELETE_STEP;
      break;
  }
  const notification = { message: { done }, track };

  const payload = {
    id,
    node,
  };

  return new AuthoringAction(type, action, payload, notification);
};

/**
 * Creates an action 'command' that will move a subsection to a new section OR a step/item to a new subsection (or to the bin/save for later)
 */
export const moveNode = (
  type: ActionType,
  node: PathwayNode
): AuthoringAction => {
  const action = 'move';
  let done: string;
  let payload = {};
  switch (type) {
    case PathwayLevel.SUBSECTION:
      done = PathwayActionSuccess.MOVE_SUBSECTION;
      payload = { subsection: node as PathwaySubsection };
      break;
    case PathwayLevel.STEP:
      done = PathwayActionSuccess.MOVE_STEP;
      payload = { step: node as PathwayStep };
      break;
  }
  return new AuthoringAction(type, action, payload, {
    message: { done },
  });
};

/**
 * Create an action 'command' that will "reorder" a pathway
 * section, subsection, step.
 * Note: 'subsection' will be undefined if reordering a section / subsection
 */
export const reorderNode = (
  type: ActionType,
  node: string,
  subsection?: PathwaySubsection
): AuthoringAction => {
  const action = 'reorder';
  let done: string;
  let track: PathwayTracking;
  switch (type) {
    case PathwayLevel.SECTION:
      done = PathwayActionSuccess.REORDERED_SECTION;
      track = PathwayTracking.REORDER_SECTION;
      break;
    case PathwayLevel.SUBSECTION:
      done = PathwayActionSuccess.REORDERED_SUBSECTION;
      track = PathwayTracking.REORDER_SUBSECTION;
      break;
    case PathwayLevel.STEP:
      done = PathwayActionSuccess.REORDERED_STEP;
      track = PathwayTracking.REORDER_STEP;
      break;
  }

  const notification = { message: { done }, track };
  const payload = { node, subsection };

  return new AuthoringAction(type, action, payload, notification);
};
