import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';

import { PathwayUserBaseDirective } from '@app/pathways/directives/pathway-user-base.directive';
import { EventBus, ScrollService } from '@app/shared/services';
import {
  hasFauxUserSubsection,
  sectionTitleToAriaLabel,
} from '@dg/pathways-rsm';

/**
 * This is a presentation component that displays a list of pathway sections
 * and will auto scroll to the in-progress section if specified
 *
 * NOTE: all input from container component are read-only
 */
@Component({
  selector: 'dgx-pathway-sections',
  templateUrl: './pathway-sections.component.html',
  styleUrls: ['./pathway-sections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwaySectionsComponent
  extends PathwayUserBaseDirective
  implements AfterViewInit
{
  @Input() showSkeleton: boolean = true;
  @Input() firstSectionInProgress: string;
  @Input() hideDuration: boolean;
  @Input() hideFeedback: boolean = true;
  @Input() pathwayId: number;
  @Input() submittedSurvey: boolean;

  constructor(
    public element: ElementRef,
    private scrollService: ScrollService,
    eventBus: EventBus
  ) {
    super(eventBus);
  }

  public ngAfterViewInit() {
    this.scrollToSection();
  }

  // ***************************
  // PUBLIC -------------------
  // Util Wrappers
  // ***************************

  public getTitleForAriaLabel = sectionTitleToAriaLabel;
  public hasFauxSubsection = hasFauxUserSubsection;

  // ***************************
  // Methods
  // ***************************

  /**
   * Auto scroll to in-progress section; if specified (by PathwayFacade)
   */
  public scrollToSection() {
    if (!this.firstSectionInProgress) {
      return;
    }

    const document = this.element.nativeElement.ownerDocument;
    const el = document.getElementById(this.firstSectionInProgress);

    if (!el) {
      return;
    }

    this.scrollService.scrollToElementByReference(el);
    this.firstSectionInProgress = null;
  }
}
