import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { Facet, FacetValue } from '@app/shared/models/core-api.model';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import { PopoverService } from '@app/shared/services/popover.service';
import { WindowToken } from '@app/shared/window.token';
import { DfIconChevronDown12, DfIconRegistry } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { isEqual as _isEqual } from 'lodash-es';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { DfIconSize } from '@lib/fresco';
import { Option } from '../select/select.component';

// extend Facet and FacetValue
export interface FilteredFacet extends Facet {
  /** Array of values for a given facet. *Stripped when FacetFilter is created.* */
  values?: FilteredFacetValue[];
}
export interface FilteredFacetValue extends FacetValue {
  /** Property optionally added for display with `df-icon`. */
  icon?: FilterIcon;
}

/**
 * Filter created from a server facet.
 */
export interface Filter {
  // required values
  /**
   * The ID value for the filter. *Defaults to `filter.title`, which should work
   * in almost all situations.*
   */
  id: number | string;
  /**
   * A display title for the filter. *Should be translated. Defaults to
   * (translated) `Core_${filter.title}`.*
   */
  title: string;
  /** Array of filters. *Added programmatically.* */
  filters: FacetFilter[];
  // optional values, depending on filter type
  /** Whether the filter options themselves are searchable. *For popover filters.* */
  canSearch?: boolean;
  /**
   * The ID value for a given checkbox filter. *Defaults to the ID of the first
   * (should be only) value.*
   */
  checkboxFilterId?: string;
  /**
   * To display custom checkbox name.
   * Defaults to filter title
   * filter component isn't responsible for translation,
   * you should translate it before it gets to this point if needed
   */
  customCheckboxName?: string;
  /** Whether a multi-select filter should update automatically after a brief delay. Defaults to false */
  doAutomaticUpdate?: boolean;
  /** Creates a single-option checkbox filter. */
  isCheckboxFilter?: boolean;
  /** Disables the filter. */
  isDisabled?: boolean;
  /** Number indicator for checkbox filter. */
  checkboxFilterCount?: number;
  /** Custom Classes for checkbox filter when selected. */
  checkboxFilterClass?: string;
  /** Creates a multi-tiered filter. */
  isFilterCollection?: boolean;
  /** Used for searches outside the default catalogue. */
  organizationName?: string;
  /** Whether to display options that have no matches in the data set. *Defaults to  */
  showEmptyOptions?: boolean;
  /** Used to place an icon in front of the options in the list. *Same as server order.* */
  icons?: FilterIcon[];
  /** For filters in which enabled/disabled should be determined without the count property. */
  ignoreCount?: boolean;
  /** Limit on how many items can be checked to filter for */
  filterLimit?: number;
  /** Creates a Filter with select dropdown */
  isSelectionFilter?: boolean;
  /** Alternate title when filters are selected */
  altTitle?: string;
  /** Placehoder for select dropdown */
  placeholderTitle?: string;
  /** Tooltip for filter (currently for disabled checkbox filter) */
  tooltip?: string;
  /** Sort selected filters to top after reload */
  sortSelectedFilters?: boolean;
}

export interface FilterIcon {
  /** Should match the *ID* of the associated filter/facet. */
  id: string | number;
  /** See `DfIcon` for details. */
  name: string;
  /** For a11y and tooltip purposes. *Should already be translated.* */
  label?: string;
  /** An optional color for the icon, used with our color service (e.g. 'ebony-a18'). */
  color?: string;
  width?: DfIconSize;
  size?: DfIconSize;
}

/**
 * A copy of `ParentItemViewModel<Facet, SimpleItemViewModel<FacetValue>`
 * for convenience.
 */
export type FacetFilter = ParentItemViewModel<
  FilteredFacet,
  SimpleItemViewModel<FilteredFacetValue>
>;

@Component({
  selector: 'dgx-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent extends SubscriberBaseDirective implements OnInit {
  public readonly debounceDuration = 1500;

  // TODO: Extract all the scroll-related functionality to a new, extending component
  public isFirstScrollRun = true;
  public initialPageScrollY: any = null;
  public previousPageYOffset: any = null;

  @Input() public applyOnClose?: boolean;
  @Input() public disabledButtonClass? = 'btn-passive';
  @Input() public hideCheckboxIcon = false;
  @Input() public filter: Filter;
  @Input() public isDisabled? = false;
  @Input() public ellipsis? = 0;
  /**
   * Classes to be added to the wrapping block element of the filter. Use `m-full-width` in
   * conjunction with `wrapperClasses` to force each filter to be full-width on mobile.
   */
  @Input('wrapperClasses') public customWrapperClasses? = '';
  @Output() public filterChange = new EventEmitter<any>();
  /** This event tracks when filter being clicked or updated */
  @Output() public trackableEvent = new EventEmitter<any>();
  /** This event only track filter has been updated */
  @Output() public trackableFilterUpdateEvent = new EventEmitter<Filter>();

  public allOptionsList = [];
  public countLimit = 50;
  public currentOptions = new Set<string>();
  public displayFilters: FacetFilter[];
  public i18n = this.translate.instant([
    'Core_Clear',
    'Core_ClearFilters',
    'Core_ApplyChanges',
    'Core_Search',
    'OrgReportingCtrl_NoMatchingSkills',
    'TargetResourcesForm_Selected',
  ]);
  public initialFilters: FacetFilter[];
  public isPopoverOpen = false;
  public noResults: boolean;
  public parentContainer?: any = null;
  public previousOptions = new Set<string>();
  public searchField = new FormControl(
    // initial value for field
    '',
    // as long as this isn't required, there's no need to
    // send separate events for an emptied-out search form
    // because an empty form counts as a valid submission
    // and `distinctUntilChanged()` will prevent double empty
    // submissions.
    Validators.minLength(2)
  );
  // Selected selection dropdown filter option
  public searchResults = [];
  public selectedList = [];
  public selectedOption;
  public shouldApplyFilter = false;
  public wrapperClasses = 'm-guts-m-b-1';

  // Private
  private prevSearchTerm: string = '';
  private updateOnDelayFilter = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private iconRegistry: DfIconRegistry,
    private elRef: ElementRef,
    private popoverService: PopoverService,
    private translate: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
    this.iconRegistry.registerIcons([DfIconChevronDown12]);
  }

  public get checkboxButtonClass() {
    let buttonClass = this.disabledButtonClass;
    if (this.currentOptions.has(this.filter.checkboxFilterId)) {
      buttonClass = 'btn-primary';
    }
    return buttonClass;
  }

  public get checkboxName() {
    if (this.filter?.customCheckboxName) {
      return this.filter.customCheckboxName;
    }

    const orgName = this.filter?.organizationName;
    const title = this.translate.instant(this.filter.title);

    return [orgName, title].join(' ');
  }

  public get checkboxTooltip() {
    let tooltip = '';
    if (!this.isDisabled && this.filter.tooltip) {
      tooltip = this.filter.tooltip;
    }
    return tooltip;
  }

  public get clearFilterText(): string {
    return this.translate.instant('Core_ClearNumberFormat', {
      numberSelected: this.numSelected,
    });
  }
  public get disableCheckbox(): boolean {
    const isChecked = this.currentOptions.has(this.filter.checkboxFilterId);
    return (
      this.isDisabled ||
      this.filter.isDisabled ||
      (this.filter.checkboxFilterCount === 0 && !isChecked)
    );
  }

  public get haveOptionsChanged(): boolean {
    return !_isEqual(this.previousOptions, this.currentOptions);
  }

  public get isFilterCleared(): boolean {
    return (
      this.filter.filters.filter((filter) =>
        filter.subitems.some((item) => item.isSelected)
      ).length < 1
    );
  }

  public get limitReached(): boolean {
    // If filter has a selection limit, disable selecting
    return (
      this.filter.filterLimit && this.numSelected >= this.filter.filterLimit
    );
  }

  public get numSelected(): number {
    return this.currentOptions.size;
  }

  public get popoverButtonClass() {
    return this.isPopoverOpen ||
      (this.numSelected > 0 &&
        !this.filter.isDisabled &&
        !this.filter.isSelectionFilter)
      ? 'btn-primary'
      : this.disabledButtonClass;
  }

  public get updateOnDelay(): boolean {
    return !!this.filter.doAutomaticUpdate;
  }

  // TODO: Extract all the scroll-related functionality to a new, extending component
  @HostListener('window:scroll')
  public getScrollValue() {
    this.previousPageYOffset = this.windowRef.scrollY;
  }

  public ngOnInit(): void {
    if (!this.filter.isSelectionFilter && this.filter.sortSelectedFilters) {
      this.filter.filters = [...this.sortFilters(this.filter.filters)];
    }
    if (this.filter.canSearch) {
      // Save initial filters
      this.initialFilters = [...this.filter.filters];
      // Set display filters, so that we never alter
      // the *actual* filter.filters array
      this.displayFilters = this.filter.sortSelectedFilters
        ? [...this.sortFilters(this.filter.filters)]
        : [...this.filter.filters];

      this.searchField.valueChanges
        .pipe(
          this.takeUntilDestroyed(),
          distinctUntilChanged(),
          // As our field is not required, empty is a valid value
          map((term) => term.trim()),
          filter(() => this.searchField.valid),
          filter((term: string) => this.prevSearchTerm !== term),
          tap((term: string) => {
            this.prevSearchTerm = term;
            this.updateDisplayFilters(term);
          })
        )
        .subscribe();
    }

    // combine any custom wrapper classes with our defaults
    // (this allows the property to be present on filter.component but null,
    // which would *otherwise* count as a value per Angular.)
    this.customWrapperClasses = this.customWrapperClasses ?? 'm-full-width';
    this.wrapperClasses =
      (this.customWrapperClasses ? this.customWrapperClasses + ' ' : '') +
      this.wrapperClasses;

    this.updateOnDelayFilter
      .pipe(
        debounceTime(this.debounceDuration),
        tap(() => {
          // Apply the filter!
          this.applyFilter(true);
        })
      )
      .subscribe();

    // Set the currentOptions set to match the
    // initial value of filterOptions.
    this.updateCurrentOptions();
    // Save previousOptions for later comparisons.
    this.previousOptions = new Set(this.currentOptions);
  }

  // This is a crummy workaround. Absolutely do not replicate all the redundant
  // logic through these filters when doing the Filter Redux. But it's necessary
  // at the moment to make Clear All buttons work -- without this, changes from
  // *outside* the filter can't affect it.
  // PD-50689
  public ngOnChanges({ filter }: SimpleChanges): void {
    if (!filter || (!this.haveOptionsChanged && !this.isFilterCleared)) {
      return;
    }
    // update the actual filter
    this.resetFilter();
  }

  /**
   * Save changes and send updated filters to the server.
   */
  public applyFilter(closePopoverManually = false): void {
    // Don't call server if nothing has changed.
    if (!this.haveOptionsChanged) {
      if (closePopoverManually) {
        this.popoverService.close({ preventRefocus: true });
      }
      return;
    }
    // Handling for immediate-update filters
    if (this.updateOnDelay) {
      // Prevent looping through this function endlessly
      this.shouldApplyFilter = false;
      // Trigger our popover toggled method.
      // TODO: The *only* thing this method actually does at this point
      // is clear out selected options when the popover closes for filters
      // that have applyOnClose set to false.
      this.popoverToggled(false);
    }
    // update the actual filter
    this.updateFilters();
    this.filterChange.emit({
      id: this.filter.id,
      filters: this.filter.filters,
    });
    this.trackableFilterUpdateEvent.emit(this.filter);
    this.previousOptions = new Set(this.currentOptions);
    // Selection filters with search does not have term automatically cleared
    if (this.filter.isSelectionFilter && this.filter.canSearch) {
      this.searchField = new FormControl('', Validators.minLength(2));
      this.updateDisplayFilters('');
    }
    // Manually close the popover
    if (closePopoverManually) {
      this.popoverService.close({ preventRefocus: true });
    }
  }

  /**
   * Returns the correct icon class.
   *
   * @param model - The model property of the current option.
   */
  public getIconClass(model: any): string {
    return 'color-' + this.isOptionDisabled(model as FacetValue)
      ? 'ebony-a18'
      : model.icon.color;
  }

  public getIconSize(model): DfIconSize {
    return model.icon.width
      ? undefined
      : model.icon.size
        ? model.icon.size
        : 'medium';
  }

  /**
   * Determine whether an option is disabled or not, preferring
   * the model.count value but falling back on a different method
   * for options that do not come with a count.
   *
   * @param model - The model property of the current option.
   */
  public isOptionDisabled(model: FacetValue): boolean {
    return !this.filter.ignoreCount && model.count !== undefined
      ? !model.count
      : !this.currentOptions.has(model.id) && this.limitReached;
  }

  /**
   * Fired whenever the dgxPopover opens or closes.
   *
   * @param isPopoverOpening - New value of isPopoverOpen.
   */
  public popoverToggled(isPopoverOpening: boolean): void {
    // TODO: Extract all the scroll-related functionality to a new, extending component
    // reset first run for filter
    this.isFirstScrollRun = true;
    // If the popover is opening, we're done
    if (isPopoverOpening) {
      return;
    }
    // Handle popover closing
    if (!this.updateOnDelay) {
      if (this.applyOnClose && !this.filter.isSelectionFilter) {
        // If we are applying on close, go ahead and run apply
        // Don't apply when the filter isSelectionFilter, as it is
        // handled in SelectDropdownOption
        this.applyFilter();
      } else {
        // If we aren't saving on close, clear any checkboxes that haven't been saved
        this.updateCurrentOptions();
      }
    }
    // Otherwise, apply the filter on close
    else if (this.shouldApplyFilter) {
      this.applyFilter();
    }
  }

  /**
   * Reset the filter to its 'default' configuration,
   * AKA whatever filters were already set when the
   * user began to modify it.
   */
  public resetFilter($event?): void {
    this.currentOptions.clear();
    if (this.filter.canSearch) {
      this.updateDisplayFilters(this.searchField.value);
    }
    if ($event) {
      this.trackEvent($event);
    }
    this.applyFilter(true);
  }

  /**
   * When a checkbox value changes, add or remove it
   * from our currentOptions set.
   *
   * @param id - The ID for a given option.
   */
  public toggleOption($event: Event, id: string): void {
    if (this.updateOnDelay) {
      this.shouldApplyFilter = true;
    }

    if (this.currentOptions.has(id)) {
      this.currentOptions.delete(id);
    } else {
      this.currentOptions.add(id);
    }

    // TODO: Extract all the scroll-related functionality to a new, extending component
    // Add selected filters on selected results list on top of unselected list
    this.prepareForScroll();
    // TODO: Extract all the scroll-related functionality to a new, extending component
    this.restoreScrollPosition();
    this.trackEvent($event);
    // some filters should reload the page immediately
    if (this.filter.isCheckboxFilter) {
      this.applyFilter();
    }
    // but others should wait a couple seconds
    if (this.updateOnDelay) {
      this.updateOnDelayFilter.next();
    }
  }

  /**
   * Emit event out for parent container to get for event tracking.
   * @param event
   */
  public trackEvent(event: Event) {
    this.trackableEvent.emit(event);
  }

  // TODO: Extract all the scroll-related functionality to a new, extending component
  public prepareForScroll() {
    if (!this.elRef.nativeElement.closest('.is_fixed')) {
      if (this.isFirstScrollRun) {
        this.initialPageScrollY = this.windowRef.scrollY;
        this.isFirstScrollRun = false;
      }
    }
  }

  // TODO: Extract all the scroll-related functionality to a new, extending component
  public restoreScrollPosition() {
    const scrollDifference = this.windowRef.scrollY - this.initialPageScrollY;
    // only reposition and scroll if not in fixed mode for filters
    if (!this.elRef.nativeElement.closest('.is_fixed')) {
      this.windowRef.scroll({
        top: this.windowRef.scrollY - scrollDifference,
      });
    }
  }

  // TODO: Consolidate this, we shouldn't need two different methods for
  // toggleOption.
  /**
   * When a dropdown option is selected
   */
  public selectDropdownOption($event: Option) {
    // Mark new selection as isSelected
    for (const filter of this.filter.filters) {
      for (const option of filter.subitems) {
        option.isSelected = option.model.id === $event.model.id;
      }
    }
    this.updateCurrentOptions();
    this.applyFilter();
  }

  public trackByFn(_: number, filter: SimpleItemViewModel<FilteredFacetValue>) {
    return filter.id;
  }

  // set currentOptions to match filters
  public updateCurrentOptions(): void {
    if (!this.filter.filters?.length) {
      return;
    }
    if (!this.applyOnClose || this.filter.isSelectionFilter) {
      this.currentOptions.clear();
    }
    for (const filter of this.filter.filters) {
      for (const option of filter.subitems) {
        if (option.isSelected) {
          this.currentOptions.add(option.model.id);
          if (this.filter.isSelectionFilter) {
            this.selectedOption = option;
          }
        }
      }
    }
  }

  private searchedFilter(term, includeMatches = false) {
    return this.filter.filters.map((subfilter) => ({
      ...subfilter,
      subitems: subfilter.subitems
        .map((option) => ({
          ...option,
          isSelected: this.currentOptions.has(option.model.id),
        }))
        .filter(
          (option) =>
            option.title.toLowerCase().includes(term.toLowerCase()) ===
            includeMatches
        ),
    }));
  }

  private sortFilters(filters) {
    const selected = [],
      unselected = [],
      filterCopy = [...filters];
    filterCopy.map((subfilter) => {
      subfilter.subitems.map((option) => {
        option.isSelected ? selected.push(option) : unselected.push(option);
      });
    });
    selected.sort((a, b) => a.title?.localeCompare(b.title));
    unselected.sort((a, b) => a.title?.localeCompare(b.title));
    filterCopy[0].subitems = [...selected, ...unselected];
    return filterCopy;
  }

  private updateDisplayFilters(term: string) {
    // if initialFilters isn't set yet, exit early
    if (!this.initialFilters) {
      return;
    }
    // return filters to default if search term is empty
    if (!term) {
      this.searchResults = [];
      return (this.displayFilters = this.filter.sortSelectedFilters
        ? [...this.sortFilters(this.initialFilters)]
        : [...this.initialFilters]);
    }
    this.searchResults = this.searchedFilter(term, true);
    this.displayFilters = this.searchResults;
    this.noResults = this.searchResults[0].subitems.length === 0;
  }

  // set filters to match currentOptions
  private updateFilters() {
    this.filter.filters = this.filter.filters.map((subfilter) => ({
      ...subfilter,
      subitems: subfilter.subitems.map((option) => ({
        ...option,
        isSelected: this.currentOptions.has(option.model.id),
      })),
    }));
    // This component is OnPush, so this kind of change requires markForCheck.
    this.cdr.markForCheck();
  }
}
