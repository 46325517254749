<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.pathway">
    <div class="grid pathway-view" *ngIf="!vm.isEditMode; else showEditor">
      <div class="grid__col-sm-3 guts-p-t-0">
        <dgx-pathway-section-nav
          *ngIf="shouldShowNav(vm.pathway)"
          [pathway]="vm.pathway"
          [activeSection]="vm.activations.section"
          [activeSubsection]="vm.activations.subsection"
          (sectionClicked)="vm.activateSection($event.section)"
          (subsectionClicked)="
            vm.activateSubsection($event.section, $event.subsection)
          "
          class="pathway-view--sticky-offset is_sticky"
        ></dgx-pathway-section-nav>
      </div>
      <div class="grid__col-sm-9 guts-p-t-0">
        <dgx-pathway-sections
          [sections]="vm.pathway?.levels"
          [showSkeleton]="vm.showSkeleton"
          [firstSectionInProgress]="vm.firstSectionInProgress"
          [hideDuration]="vm.pathway.durationDisplayDisabled"
          [hideFeedback]="!vm.pathway.includeSurvey || !vm.showPathwaySurvey"
          [pathwayId]="vm.pathway.id"
          [submittedSurvey]="vm.pathway.submittedSurvey"
        ></dgx-pathway-sections>
      </div>
    </div>

    <!-- Template for Authoring (edit) mode -->
    <ng-template #showEditor>
      <div class="grid pathway-view">
        <div class="grid__col-sm-3 guts-p-t-0">
          <dgx-pathway-author-nav
            [pathway]="vm.pathway"
            [activeSection]="vm.activations.section"
            [activeSubsection]="vm.activations.subsection"
            [hasFauxSection]="hasFauxSection(vm.pathway, vm.hasFauxSection)"
            [fauxSubsections]="vm.fauxSubsections"
            (sectionClicked)="vm.activateSection($event.section)"
            (subsectionClicked)="
              vm.activateSubsection($event.section, $event.subsection)
            "
            class="pathway-view--sticky-offset is_sticky"
          ></dgx-pathway-author-nav>
        </div>
        <div class="grid__col-sm-9 guts-p-t-0">
          <dgx-pathway-author-sections></dgx-pathway-author-sections>
        </div>
        <p id="pathauthA11yBlurInstructions" class="hidden">
          {{ 'A11y_BlurToSave' | translate }}
        </p>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
