import { PathwayAutoEnrollService } from '@app/shared/services/pathway-auto-enroll.service';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { emitOnce } from '@ngneat/elf';
import { Observable, combineLatest, lastValueFrom } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

// services
import { UserInputsService } from '@app/inputs/services/user-inputs.service';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import {
  AuthService,
  ContextService,
  EventBus,
  ModalService,
  NotifierService,
  ScrollService,
  TrackerService,
  Unsubscribe,
  WindowLayoutService,
} from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';

// misc
import { BreakpointState } from '@angular/cdk/layout';
import { AuthUser } from '@app/account/account-api.model';
import {
  InputIdentifier,
  InputNotification,
} from '@app/inputs/inputs-api.model';
import { PathwayEnrollmentRSMService } from '@app/pathways/services/reactive-pathway-services/pathway-enrollment-rsm.service';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { ProfilePathsService } from '@app/routing/services/profile-paths.service';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { WindowToken } from '@app/shared/window.token';
import { readFirst } from '@dg/shared-rxjs';
import { InputActions } from '@app/inputs/inputs.enums';
import { PathwayBinItem, PathwayDetailsModel } from './pathway-api.model';
import { PathwayDataAPI } from './pathway.api';
import {
  AuthoringAction,
  GetPathwayWithPermissionsParams,
  PathwayAPI,
  PathwayActivation,
  PathwayComputedState,
  PathwayEvent,
  PathwayLevel,
  PathwayState,
  PathwayUrlQueryParams,
  PathwayViewModel,
  confirmDelete,
  share,
  toggleEnrollment,
} from './pathway.model';
import { PathwayStore } from './pathway.store';
import {
  ACTIONS,
  ActionsList,
  ActivationTrackData,
  activationForSection,
  activationForSubsection,
  announceCompletion,
  canAuthorPathway,
  getFirstSectionInProgress,
  getVisibilityString,
  isFauxlike,
  navigateFromDelete,
  updateStep,
} from './utils';
import { shouldPerformAutoEnrollment } from '@app/shared/utils/input-completion.utils';
const EMPTY_ERROR = 'EmptyError';

@Injectable({ providedIn: 'root' })
export class PathwayFacade {
  // Public properties
  public exclusionList: InputIdentifier[] = [];
  public vm$: Observable<PathwayViewModel>;
  public i18n = this.translate.instant([
    'Core_Completed',
    'Core_1Minute',
    'Core_1Hour',
  ]);

  // Private properties
  private detachActivateEventBus: Unsubscribe;
  private detachRefreshEventBus: Unsubscribe;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userInputs: UserInputsService,
    private tracker: TrackerService,
    private scrollService: ScrollService,
    private enrollment: PathwayEnrollmentRSMService,
    private eventBus: EventBus,
    private api: PathwayDataAPI,
    private store: PathwayStore,
    private translate: TranslateService,
    private notifier: NotifierService,
    private profilePathsService: ProfilePathsService,
    private orgEndorsedService: OrgEndorsedService,
    private modalService: ModalService,
    private contextService: ContextService,
    private recommendationsModalService: RecommendationsModalService,
    private recommendationsService: RecommendationsService,
    private windowLayoutService: WindowLayoutService,
    private pathwayAutoEnrollService: PathwayAutoEnrollService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.vm$ = combineLatest([
      this.store.state$,
      this.authService.authUser$,
      this.windowLayoutService.registerMediaListener(
        [
          this.windowLayoutService.dgBreakpoints.BabyBear,
          this.windowLayoutService.dgBreakpoints.BrotherBear,
        ],
        false
      ),
    ]).pipe(
      map(this.addComputedProperties.bind(this)),
      map(this.addViewModelAPI.bind(this)),
      // NOTE: Because this vm$ is set up 1x forever. If the refCount === true,
      //       then combineLatest can be unsubscribed automatically and never reconnected.
      //       In our case, we never want to unsubscribe.
      shareReplay<PathwayViewModel>({ refCount: false, bufferSize: 1 })
    );

    // Watch the URL for changes to editMode
    const updateEditMode = this.store.updateEditMode.bind(this.store);
    this.route.queryParams.subscribe(updateEditMode);

    this.watchAutoEnrollmentActions();

    this.watchInputActions();

    this.detachActivateEventBus = this.eventBus.on(
      ActionsList.ACTIVATE,
      this.applyPathwayActivationEventBusAction.bind(this)
    );
    this.detachRefreshEventBus = this.eventBus.on(
      ActionsList.REFRESH,
      this.applyPathwayRefreshEventBusAction.bind(this)
    );
  }

  // Computed public properties
  /**
   * Easy access to current snapshot of [read-only] PathwayViewModel
   * ...
   */
  public get snapshot(): PathwayViewModel {
    return readFirst(this.vm$);
  }

  public ngOnDestroy() {
    this.detachActivateEventBus();
    this.detachRefreshEventBus();
  }

  // *******************************************************
  // Public methods
  //
  // NOTE: these async methods return when the requested action is complete
  //       NOT when the store has been updated
  // *******************************************************

  /**
   * Load pathway and permissions, check to auto enroll for SSO, and track view
   */
  public async loadPathway(
    params: GetPathwayWithPermissionsParams,
    urlParams?: PathwayUrlQueryParams
  ): Promise<boolean> {
    const updateEnrollment = () => this.store.updateEnrollment(true);
    const trackStatus = this.store.trackLoadStatus();

    // TODO: displaying the skeleton like this wont work while the pathway API call is in the route guard :(
    // we should be splitting the current API into two: permissions and the data separate
    // this.store.showSkeleton(); // Whenever loading pathway(s), show skeleton

    const results = await this.api.loadFullPathway(params, trackStatus);

    // Note: this check is only for tests, if the API fails it will be caught in the catchError
    if (!results) {
      return false;
    }

    const { pathway, userPermissions, exclusionList } = results;
    this.exclusionList = exclusionList;

    emitOnce(() => {
      this.initializeFauxContainers(pathway);
      this.store.updatePathwayAndPermissions(pathway, userPermissions);
      // Only need to load the bin if the user can edit the pathway
      if (userPermissions.canEditPathway) {
        this.loadBin(pathway.id);
      }
      this.updateVersion(pathway.id, pathway.version);
    });

    await Promise.all([
      this.api
        .autoEnrollForSSO(pathway, urlParams)
        .then((success) => success && updateEnrollment()),
      this.api.updateDocumentTitle(pathway),
    ]);
    return true;
  }

  /*************************************************
   * PATHWAY API
   *************************************************/
  /**
   * Toggle the enrollment on the pathway (non-edit mode)
   */
  public async toggleEnrollment(): Promise<boolean> {
    const { pathway } = this.snapshot;

    return this.editWith(
      toggleEnrollment(
        !pathway.isEnrolled,
        pathway.privacyLevel,
        pathway.id || pathway.resourceId
      )
    );
  }

  /**
   * Show the pathway confirm delete modal (in edit&non-edit mode)
   */
  public async showConfirmDelete(): Promise<boolean> {
    return this.editWith(confirmDelete());
  }

  /**
   * Show the share modal
   */
  public async showShare(focusElement: HTMLElement) {
    return this.editWith(share(focusElement));
  }

  /**
   * Updates the pathway version when changes are made to pathway apis.  Used to prevent concurrent editing of pathways.
   * Why does the facade have this method? Because the pathway has business logic + storage needs
   */
  public async updateVersion(
    pathwayId: number,
    version?: string
  ): Promise<boolean> {
    if (!version) {
      version = await this.api.getVersion(pathwayId);
    }
    this.store.updateVersion(version);
    return true;
  }

  public updateSubmittedSurvey(submittedSurvey: boolean) {
    this.store.updateSurveySubmitted(submittedSurvey);
  }

  /**
   * When anything 'requests' that a pathway part be activated/selected,
   * then we update the pathway state and re-emit updated activation state.
   *
   * NOTE: here we centralize all activation AND also notify trackers/scroll service
   *
   * @param event - The data passed as a PathwayEvent for updating our activation.
   * @param scrollToElement - Whether or not to actually scroll. We only want to
   * scroll when this was activated by clicking on a nav link, *not* when it was
   * activated by scrolling through the page and triggering onIntersection events.
   */
  public updateActivation(
    { data: selected }: PathwayEvent,
    scrollToElement = false
  ): void {
    let info: ActivationTrackData;
    const { activations: current, pathway, totalSections } = this.snapshot;
    const isSameSection = selected.section === current.section;
    const isSameSubsection = selected.subsection === current.subsection;

    // Sanity-check: don't do *anything* if we aren't actually *moving*.
    if (isSameSection && isSameSubsection) {
      return;
    }

    switch (selected.type) {
      case PathwayLevel.SECTION:
        const resetToFirstStep = isSameSection && current.subsection;
        if (resetToFirstStep) selected.subsection = 1;
        if (selected.section > totalSections) return; // range checks

        info = activationForSection(pathway, selected);
        break;
      case PathwayLevel.SUBSECTION:
        info = activationForSubsection(pathway, selected);
        break;
      default:
        console.error('Should never be hit, as we do not activate on steps.');
        return;
    }

    // Update the actual activations.
    this.store.updateActivations(selected);
    // Update trackers
    this.tracker.trackEventData(info.trackData);
    // *If* we are scrolling to the element, do so.
    if (scrollToElement) {
      this.scrollService.scrollToElementById(info.elementId);
    }
  }

  // *******************************************************
  // Private Event Bus intermediaries
  // *******************************************************
  private applyPathwayActivationEventBusAction({
    type,
    section,
    subsection,
  }: Partial<PathwayActivation>) {
    return this.updateActivation(
      ACTIONS.activateNav(type, section, subsection)
    );
  }

  private applyPathwayRefreshEventBusAction() {
    const { pathway } = this.snapshot;
    return this.loadPathway({ pathId: pathway.id });
  }

  //
  // Private Faux Container handling
  //

  // This should be run *inside* an `emitOnce` callback.
  private initializeFauxContainers(pathway: PathwayDetailsModel): void {
    const hasFauxSection = isFauxlike(pathway.levels);
    const fauxSubsections =
      pathway.levels
        ?.filter(({ lessons }) => !!lessons && isFauxlike(lessons))
        // array of nodes
        .map(({ lessons }) => lessons[0]?.node)
        // no null values!
        .filter((node) => !!node) || [];

    this.store.initializeFauxSettings({ hasFauxSection, fauxSubsections });
  }

  // *******************************************************
  // Private subscriptions to external services
  // *******************************************************
  private watchAutoEnrollmentActions() {
    const onPathwayAutoEnrolledBeforeInputCompletion = (
      hasAutoEnrolled: boolean
    ) => {
      if (hasAutoEnrolled) {
        this.store.updateEnrollment(true);
      }
    };
    this.pathwayAutoEnrollService.pathwayAutoEnrolledBeforeInputCompletion.subscribe(
      onPathwayAutoEnrolledBeforeInputCompletion
    );
  }

  private watchInputActions() {
    const onInputCompleted = (input: InputNotification) => {
      const isInputComplete = input.action === InputActions.completed;
      // always update the learner regardless of action type
      const { isEditMode, pathway } = this.snapshot;
      if (!isEditMode) {
        const pathwayUpdated = updateStep(input, pathway);
        this.store.updatePathway(pathwayUpdated);
      }

      // Upon input completion, auto-enroll IF the pathway is not already enrolled AND the input is not the last required input to be completed
      // We do not want to auto-enroll after the last input is completed because duplicate automations could be triggered on the backend
      if (shouldPerformAutoEnrollment(pathway, input, isInputComplete, true)) {
        this.enrollment.autoEnroll(this.snapshot.pathway).then(() => {
          this.store.updateEnrollment(true);
        });
      }
    };
    // Let's for all
    this.userInputs.inputsChange.subscribe(onInputCompleted);
  }

  // *******************************************************
  // Private fields
  // *******************************************************

  // Build the API that the view model exposes to the view
  private _api: PathwayAPI = {
    // Facade delegates 1st since we have business logic
    updateVersion: this.updateVersion.bind(this),

    // Allows UI to request pathway section/Subsection/step activation

    /**
     * Call updateActivation with section parameters.
     * Uses a *faux* PathwayEvent to avoid double-triggering the event bus.
     *
     * @param section - Section number to update.
     */
    activateSection: (section: number) =>
      this.updateActivation(
        {
          data: {
            type: PathwayLevel.SECTION,
            section,
          },
        } as PathwayEvent,
        true
      ),
    /**
     * Call updateActivation with subsection parameters.
     * Uses a *faux* PathwayEvent to avoid double-triggering the event bus.
     *
     * @param section - Section number to update.
     * @param subsection - Subsection number to update.
     */
    activateSubsection: (section: number, subsection: number) =>
      this.updateActivation(
        {
          data: {
            type: PathwayLevel.SUBSECTION,
            section,
            subsection,
          },
        } as PathwayEvent,
        true
      ),
  };

  // *******************************************************
  // ViewModel 'injection' methods
  // *******************************************************

  /**
   * Inject the Pathway computed properties into view model
   * NOTE: some properties are already in Pathway... we compute
   * for easy access in views
   */
  private addComputedProperties([state, authUser, breakpointState]: [
    PathwayState,
    AuthUser,
    BreakpointState,
  ]): PathwayState & PathwayComputedState {
    // Bail if undefined pathway
    if (!state.pathway) {
      return;
    }
    // Compute some properties for easy access in views
    const firstSectionInProgress = getFirstSectionInProgress(state.pathway);
    const canAuthor = canAuthorPathway(
      state.pathway,
      state.permissions,
      authUser
    );
    const isMobileView = breakpointState.matches;
    const numOfGroups = state.pathway?.groupIds
      ? state.pathway?.groupIds.length
      : 0;
    const computed: PathwayComputedState = {
      totalSections: state.pathway?.levels.length || 0,
      totalSteps: state.pathway?.totalSteps || 0,
      totalStepsDisplay: state.pathway?.totalSteps
        ? state.pathway.totalSteps === 1
          ? this.translate.instant('Pathways_ItemCountSingular')
          : this.translate.instant('Pathways_ItemCountFormat', {
              count: state.pathway.totalSteps,
            })
        : undefined,
      totalCompletedSteps: state.pathway?.completedSteps || 0,
      optionalSteps: state.pathway?.optionalSteps || 0,
      optionalStepsDisplay:
        state.pathway?.optionalSteps > 0
          ? state.pathway.optionalSteps === 1
            ? this.translate.instant('Pathways_OptionalItemCountSingular')
            : this.translate.instant('Pathways_OptionalItemCountFormat', {
                count: state.pathway.optionalSteps,
              })
          : undefined,

      isMobileView,
      canAuthor,
      isEditMode: state.isEditMode,
      inviteUrl: state.pathway?.inviteUrl || '',
      visibilityLabel: getVisibilityString(
        state.pathway?.privacyLevel,
        this.translate,
        numOfGroups
      ),

      firstSectionInProgress,
      isComplete: state.pathway?.progress === 100,
      durationHours: !state.pathway?.durationHours
        ? null
        : state.pathway.durationHours === 1
          ? this.i18n.Core_1Hour
          : `${this.translate.instant('Core_HoursFormat', {
              hours: state.pathway.durationHours,
            })}`,
      durationMinutes: !state.pathway?.durationMinutes
        ? null
        : state.pathway.durationMinutes === 1
          ? this.i18n.Core_1Minute
          : `${this.translate.instant('Core_MinutesFormat', {
              minutes: state.pathway.durationMinutes,
            })}`,
      hideHeaderImage:
        state.pathway?.headerImageDisabled || !state.pathway?.imageUrl,

      sectionTotalDisplay: this.translate.instant(
        'Pathways_SectionCountFormat',
        {
          count: state.isEditMode
            ? state.pathway?.levels.length || 0
            : state.pathway.totalLevelsWithItems,
        }
      ),
      endorsedImageUrl:
        this.orgEndorsedService.getEndorsedSrc(
          this.authService.authUser?.defaultOrgInfo?.organizationId
        ) || '',
      isChannel: this.contextService.isChannel(),
      canRecommend: this.recommendationsService.canRecommendResource({
        isValid:
          state.permissions.canRecommendItems &&
          !this.contextService.urlHasContext(
            this.windowRef.location.search,
            'channel'
          ) &&
          !state.isEditMode,
        privacyLevel: state.pathway.privacyLevel,
      }),
      isConsumerUser: !authUser?.defaultOrgInfo,
      disableLearnerSkillsRegistry:
        authUser?.disableLearnerSkillsRegistry !== undefined
          ? authUser.disableLearnerSkillsRegistry
          : true,
      showPathwayBadgeTrigger:
        authUser?.defaultOrgInfo?.settings?.enablePathwayBadgeTrigger,
      showPathwaySurvey:
        authUser?.defaultOrgInfo?.settings?.pathwayFeedbackSurvey,
    };
    return {
      ...state,
      ...computed,
    };
  }

  /**
   * Inject the Facade API into view model
   *
   * These delegate methods allow views template code to easily call
   * back into the Pathway Reactive Store (Facade + Store)
   */
  private addViewModelAPI(
    state: PathwayState & PathwayComputedState
  ): PathwayState & PathwayComputedState & PathwayAPI {
    return {
      ...state,
      ...this._api,
    };
  }

  /**
   * Processes Pathway actions
   */
  private async editWith(command: AuthoringAction): Promise<boolean> {
    try {
      await this.pathwayAction(command);
      announceCompletion(command, this.tracker, this.notifier, this.translate); //
      return true;
    } catch (e) {
      if (e?.name === EMPTY_ERROR) return false;
      // If the error is 404 (possibly the pathway has been deleted)
      const status = e.innerError?.status ? e.innerError?.status : e.status;
      if (status === 404) {
        this.router.navigateByUrl(`/error-handler/${status}`);
        return false;
      }
      // propagate to global error handler
      throw e;
    }
  }

  /**
   * Load the pathway authoring bin(hold for later) items to the store.
   *
   * @param id pathway ID
   * @returns
   */
  private async loadBin(id): Promise<boolean> {
    const binItems: PathwayBinItem[] = await this.api.getPathAuthoringBin(id);
    this.store.updateItemBin(binItems);
    return true;
  }

  /**
   * Use API service to complete pathway action
   * Then update the store
   */
  private async pathwayAction(command: AuthoringAction) {
    const { isChannel, pathway } = this.snapshot;

    switch (command.action) {
      case 'toggleEnrollment': {
        if (!pathway.isEnrolled) {
          await this.api.enroll(pathway, false, '');
          this.store.updateEnrollment(true);
          break;
        }
        await this.openConfirmationModal();
        await this.api.unenroll(pathway);
        this.store.updateEnrollment(false);
        break;
      }
      case 'share': {
        const isPersonalPathway =
          pathway.organizationId === undefined &&
          pathway.privacyLevel === Visibility.private &&
          pathway.authorProfileKeys.length > 0;
        const isPublished =
          !isPersonalPathway && pathway.privacyLevel !== Visibility.private;

        if (isPersonalPathway || !isPublished) {
          await this.displayCannotShareWarning();
        } else {
          await this.openShareModel(command.payload.focusElement);
        }
        break;
      }
      case 'confirmDelete': {
        const { id } = pathway;
        const confirmedDelete = await this.openConfirmDeleteModal();

        if (confirmedDelete) {
          await this.api.deletePathway(id);
          navigateFromDelete(this.router, this.profilePathsService, isChannel);
        }
        break;
      }
    }
  }

  // ****************************************************************************************************
  // Internal Utils
  // ****************************************************************************************************
  private async openConfirmationModal() {
    const isUnenrolledConfirmed$ =
      this.enrollment.showUnenrollConfirmationModal();

    return lastValueFrom(isUnenrolledConfirmed$);
  }

  /**
   * Open the confirm delete modal. On submitting return true to delete the pathway.
   */
  private async openConfirmDeleteModal() {
    const inputs: SimpleModalInputBindings = {
      headerText: this.translate.instant('Core_AreYouSure'),
      bodyText: this.translate.instant('Pathways_DeletePathwayBody'),
      canCancel: true,
      submitButtonType: 'destructive',
      submitButtonText: this.translate.instant('Core_YesSure'),
      item: true,
    };
    const request$ = this.modalService.show<boolean>(SimpleModalComponent, {
      inputs,
    });

    return lastValueFrom(request$);
  }

  /**
   * Open the share modal / this is also the trackable link modal
   *
   * @param focusReturnEl element to return focus to
   */
  private async openShareModel(focusReturnEl) {
    const pathway: PathwayDetailsModel = {
      ...this.snapshot.pathway,
      resourceType: 'Pathway',
    };

    const request$ = this.recommendationsModalService.showShareModal(
      pathway,
      focusReturnEl
    );
    return lastValueFrom(request$);
  }

  /**
   * Displays the can not share warning message modal.
   * NOTE: not sure how this is displayed
   */
  private displayCannotShareWarning() {
    const inputs: SimpleModalInputBindings = {
      bodyText: this.translate.instant('Pathways_PathwayPrivateMessage'),
      submitButtonText: this.translate.instant('Core_Okay'),
    };
    const request$ = this.modalService.show(SimpleModalComponent, {
      inputs,
    });
    return lastValueFrom(request$);
  }
}
